<template>
  <div>
    <el-tree
        class="tree-border"
        :data="deptOptions"
        show-checkbox
        default-expand-all
        ref="dept"
        node-key="dpt_id"
        :check-strictly="checkStrictly"
        empty-text="加载中，请稍后"
        :props="deptProps"
    ></el-tree>
  </div>
</template>

<script>
export default {
  name: "DeptTree",
  data() {
    return {
      // 部门列表
      deptOptions: [],
      deptProps: { value: 'dpt_id',label:'name',children:'groups' },
      checkStrictly:false
    }
  },
  methods:{
    async getOptions() {
      let {list} = await this.$api.getMcnGroupDeptOption()
      this.deptOptions = list
    },
  },
  created() {
    this.getOptions()
  }
}
</script>

<style scoped>

</style>