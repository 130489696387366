<template>
  <div>
    <el-table :data="dataList" highlight-current-row @row-dblclick="rowDblclick">
      <!--      <el-table-column prop="id" label="任务Id"></el-table-column>-->
      <el-table-column prop="sender_name" label="发送人" width="60"></el-table-column>
      <el-table-column prop="send_status_alias" label="发送状态" width="80"></el-table-column>
      <el-table-column prop="send_time" label="发送时间" width="140" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span :title="`创建时间：${row.created_at} 修改时间：${row.updated_at}`">
            {{ row.send_time || '--' }}
          </span>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="created_at" label="创建时间" width="140" show-overflow-tooltip></el-table-column>-->
      <el-table-column prop="req_param.remark" label="备注" show-overflow-tooltip></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "NotifyTaskList",
  props: {
    dataList: {
      type: Array
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    rowDblclick(row, column, event) {
      this.$emit('rowDblclick', row)
    }
  }
}
</script>

<style scoped>

</style>