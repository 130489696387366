<template>
  <div style='z-index: 99999'>
    <div v-show='menuData.visible' :style="{left:menuData.left+'px',top:menuData.top+'px'}" class='menu'>
      <div class='contextmenu__item' @click="checkEvent('1')" v-if='id&&menuList.edit'>
        <i class='el-icon-edit'></i> 修改
      </div>
      <!--      <div class="contextmenu__item" @click="checkEvent('2')" v-if="id&&menuList.copy">-->
      <!--        <i class="el-icon-document-copy"></i> 复制一条-->
      <!--      </div>-->

      <div class='contextmenu__item' @click="checkEvent('3')" v-if='id&&menuList.del'>
        <i class='el-icon-delete'></i> 删除
      </div>
      <div class='contextmenu__item' @click="checkEvent('log')"
           v-if="id&&userPermissions.indexOf('product_log')>-1&&menuList.log">
        <i class='el-icon-document'></i> 日志
      </div>
      <div class='contextmenu__item' @click="checkEvent('ppt')" v-if='id&&menuList.ppt||false'>
        <i class='el-icon-data-analysis'></i>PPT
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Contextmenu',
  props: {
    menuData: {
      type: Object,
      default() {
        return {
          visible: false,
          top: 0,
          left: 0
        }
      }
    },
    id: {
      type: [Number, String],
      default() {
        return undefined
      }
    },
    menuList: {
      type: Object,
      default() {
        return {
          edit: true,
          log: true,
          del: true,
          copy: true
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  watch: {
    menuData: {
      deep: true,
      handler() {
        // this.initList()
      }
    }
  },
  data() {
    return {
      menuVisible: false
    }
  },
  methods: {
    closeMenu() {
      this.menuData.visible = false
    },
    checkEvent(val) {
      this.$emit('change', val)
      this.menuData.visible = false
    }
  }
}
</script>

<style scoped>

.contextmenu__item {
    display: block;
    line-height: 34px;
    text-align: center;
}

.contextmenu__item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu {
    position: absolute;
    background-color: #fff;
    width: 100px;
    /*height: 106px;*/
    font-size: 14px;
    color: #444040;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    white-space: nowrap;
    z-index: 1000;
}

.contextmenu__item:hover {
    cursor: pointer;
    background: #ff3176;
    border-color: #ff3176;
    color: #fff;
}
</style>
