<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose'
               :close-on-click-modal="false" width='1400px'>
      <div style='width: 100%;text-align: center;font-size: 24px;font-weight: bold;margin-bottom: 8px'>
        {{ currentTitle }}
      </div>
      <el-row :gutter='10' style='min-height: 420px'>
        <el-col :span='9'>
          <el-card>
            <div slot="header" class="clearfix">
              <span>排期信息</span>
              <span style='float: right'>
               <el-button type='text' @click='onOpen'>当前排期红人：{{info.nickname}}</el-button>
              </span>
            </div>
            <div v-if='userPermissions.indexOf("lb_kol_history_all")>-1'>
              <el-form :model='searchData' size='mini' inline>
                <el-form-item label=''>
                  <el-date-picker
                    v-model='searchData.ym'
                    type='month'
                    value-format='yyyy-MM'
                    format='yyyy年MM月'
                    @change="getKolList"
                    placeholder='选择月份' style='width: 140px'>
                  </el-date-picker>
                </el-form-item>
                <el-form-item label=''>
                  <artist-search @handleSelect="selectArtist" style="width: 200px;display: inline;"/>
                </el-form-item>
                <el-form-item>
                  <el-button type='primary' @click='getKolList' icon='el-icon-search'></el-button>
                </el-form-item>
              </el-form>
            </div>
            <div v-else>
              <el-button type='text' @click='onOpen' icon='el-icon-search'>当前红人：{{info.nickname}}</el-button>
            </div>
            <div class='default-table'>
              <el-table :data='historicalList'
                        max-height='500'
                        @selection-change='handleSelectionChange'
                        :columns='historicalColumns'
                        @row-click='dbRowClick'
                        highlight-current-row
                        :border='true'>
                <el-table-column type='index' label='序号' width='55' align='center'></el-table-column>
                <el-table-column label='排期名称' min-width='255' header-align='center' align='left'>
                  <template slot-scope='{row}'>
                    {{ $utils.parseTime(row.start_at, '{y}年{m}月{d}日') }}
                    【{{ row.nickname }}】{{ row.platform_name }}
                  </template>
                </el-table-column>
              </el-table>
              <el-row>
                <el-col :span='24'>
                  <Pagination :small='true' :limit.sync='pagingProduct.page_size'
                              :page.sync='pagingProduct.current_page'
                              :total='pagingProduct.total'
                              :layout="'total, prev, pager, next'"
                              @pagination='getKolList'/>
                </el-col>
              </el-row>
            </div>

          </el-card>
        </el-col>
        <el-col :span='15' v-if='rowId'>
          <el-card>
            <div slot="header" class="clearfix">
              <span>选品列表</span>
            </div>
            <el-form :model='searchCondition' inline>
              <el-form-item label=''>
                <el-input v-model='searchCondition.title' placeholder='产品名称' clearable
                          @change='getList'></el-input>
              </el-form-item>
              <!--            <el-form-item label=''>-->
              <!--              <el-input v-model='searchCondition.brand_name' placeholder='品牌名称' clearable-->
              <!--                        @change='getList'></el-input>-->
              <!--            </el-form-item>-->
              <el-form-item label=''>
                <el-select v-if="historicalType===1" clearable v-model="searchCondition.state" placeholder="红人意见"
                           @change='getList'>
                  <el-option
                      v-for="item in stateOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' icon='el-icon-search' @click='getList'
                           size='mini'>搜索
                </el-button>
              </el-form-item>
            </el-form>
            <div class='default-table'>
              <m-table :showIndex='false' :tableData.sync='dataList'
                       @selection-change='handleSelectionChange'
                       :columns='historicalType===1?productColumns:liveColumns'
                       row-key='id'
                       :border='true'
                       max-height='500'
              >
                <el-table-column type='selection' width='40'
                                 reserve-selection slot='first-column'/>
                <el-table-column type='index' width='55'
                                 slot='first-column' label='序号' align='center'></el-table-column>
              </m-table>
              <Pagination :small='true' :limit.sync='dressPagination.page_size'
                          :page.sync='dressPagination.current_page'
                          :total='dressPagination.total'
                          :layout="'total, prev, pager, next'"
                          @pagination='getList'/>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row style='margin-top: 20px'>
        <el-col :span='24'>
          <div style='text-align: center'>
            <el-button size='big' @click='close'>取消</el-button>
            <el-tooltip class='item' effect='dark' :content='contentText' placement='bottom'>
              <el-button @click='saveBtn' size='big' type='primary' icon='el-icon-plus'>
                添加数据{{ selectData.length != 0 ? `(${selectData.length}条)` : '' }}
              </el-button>
            </el-tooltip>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import { mapGetters } from 'vuex'

export default {
  name: 'HistoricalSchedule',
  components: {
    ArtistSearch
  },

  data() {
    return {
      dataList: [],
      dressPagination: {
        current_page: 1,
        page_size: 10,
        total: 0
      },
      historicalList: [],
      info: {},
      historicalColumns: [
        {
          title: '历史直播选品',
          width: 120
        }
      ],
      pagingProduct: {
        current_page: 1,
        page_size: 10,
        total: 0
      },
      productColumns: [
        { title: '产品图片', value: 'cover_url', type: 'image', width: 50 },
        { title: '红人意见', value: '_state', width: 80 },
        { title: '产品/品牌', value: 'title', field: 'title', align: 'left', width: 140 },
        { title: '尺码/颜色', value: 'spec_name', width: 120 },
        { title: '日常价', value: 'price', width: 80 },
        { title: '直播价', value: 'lb_price', width: 80 }
      ],
      liveColumns: [
        { title: '产品图片', value: 'cover_url', type: 'image', width: 50 },
        { title: '产品/品牌', value: 'title', field: 'title', align: 'left', width: 140 },
        { title: '尺码/颜色', value: 'spec_name', width: 120 },
        { title: '日常价', value: 'price', width: 80 },
        { title: '直播价', value: 'lb_price', width: 80 }
      ],
      selectData: [],
      currentRow: {},
      diagType: '',
      rowId: 0,
      searchCondition: {},
      stateOptions: [
        { label: '上播', value: 1, color_value: '#67C23A', class_name: 'status-new' },
        { label: '不上播', value: 2, color_value: '#909399', class_name: 'status-launch' },
        { label: '待定', value: 0, color_value: '#409EFF', class_name: 'status-original' }
      ],
      searchData: {},
      KolDataList: [],
      listType: 'kol'

    }
  },
  props: {
    lbId: {
      type: [Number, String]
    },
    historicalType: {
      type: [Number, String]
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    contentText() {
      let returnText = '请选择数据'
      if (this.selectData.length != 0) {
        let data = this.selectData.map((i) => {
          return i.title || ''
        })
        returnText = String(data)
      }
      return returnText
    },
    currentTitle() {
      return this.currentRow.id ? `${this.$utils.parseTime(this.currentRow.start_at, '{y}年{m}月{d}日')}【${this.currentRow.nickname}】${this.currentRow.platform_name} 的【${this.historicalType === 1 ? '服饰选品' : '历史直播'}】列表` : ''
    }
  },
  methods: {
    onOpen() {
      this.historicalList = []
      this.dataList = []
      this.selectData = []
      this.currentRow = {},
          this.rowId = null
      this.getInfo()
      this.listType = 'kol'
    },
    onClose() {
      this.historicalList = []
      this.KolDataList = []
      this.dataList = []
      this.selectData = []
      this.currentRow = {}
      this.dressPagination = {
        current_page: 1,
        page_size: 10,
        total: 0
      }
      this.pagingProduct = {
        current_page: 1,
        page_size: 10,
        total: 0
      }
      // this.$emit('')
    },
    close() {

      this.$emit('update:visible', false)
    },
    // eslint-disable-next-line no-unused-vars
    dbRowClick(row, column, event) {
      this.dressPagination.current_page = 1

      this.currentRow = { ...row }
      this.rowId = row.id

      this.getList()
    },
    // close() {
    //   this.$emit('update:historicalDialog', false)
    //   this.$emit('close')
    // },
    async saveBtn() {
      if (this.selectData.length === 0) {
        this.$notify.error('请选择添加数据')
      } else {
        //这里保存数据
        await this.saveSelections()
      }
    },
    async saveSelections() {
      let ids = this.selectData.map((item) => {
        return item.id
      })

      let info = await this.$api.appendLbDressSelectionByHistory(this.lbId, ids)
        if (info) {
          this.$notify.success('保存成功')
          this.close()
        }
      // await this.saveProducts(postData)
    },
    // async saveProducts(postData) {
    //   let info = await this.$api.saveLbScheduleProducts(postData)
    //   if (info) {
    //     this.$notify.success('保存成功')
    //     this.close()
    //   }
    // },
    async getHistoryLbList() {
      this.historicalList = []
      if (this.info && this.info.artist_id) {
        let params = {
          artist_id: this.info.artist_id,
          order: { start_at: 'desc' },
          type: this.historicalType
        }
        let { list, pages } = await this.$api.getLbScheduleList(params)
        this.$nextTick(() => {
          this.historicalList = list.map((i) => {
            return i.id !== this.lbId ? i : false
          }).filter(Boolean)
          //服饰选品不需要-1条.历史直播需要-1 total
          this.pagingProduct.total = this.historicalType === 1 ? pages.total : pages.total - 1
          this.pagingProduct.current_page = pages.current_page
          this.pagingProduct.page_size = pages.page_size
        })
      }
    },
    async getInfo() {
      let { info } = await this.$api.getLbScheduleInfo(this.lbId)
      this.info = info
      await this.getHistoryLbList()
    },
    async getList() {
      this.dataList = []
      // let searchCondition = { ...{ lb_id: id } }
      let searchCondition = this.handleSearchCondition()
      console.log(searchCondition)
      // dressPagination
      Object.assign(searchCondition, this.dressPagination)

      // let searchCondition = { ...this.pagingProduct, ...{ lb_id: id } }
      // this.diagType === 'selection' ? searchCondition.state = 1 : ''
      //获取产品列表
      let { list, pages } = await this.$api.getLbDressSelectionList(searchCondition)
      this.dataList = list || []
      this.dressPagination.total = pages.total
      this.dressPagination.current_page = pages.current_page
      this.dressPagination.page_size = pages.page_size
    },
    handleSelectionChange(val) {
      this.selectData = val
    },
    handleSearchCondition() {
      let cond = {}
      // if (this.searchCondition.brand_name) {
      //   cond['brand_name'] = this.searchCondition.brand_name
      // }
      if (this.searchCondition.title) {
        cond['title'] = this.searchCondition.title
      }
      if (this.searchCondition.state || this.searchCondition.state === 0) {
        cond['state'] = this.searchCondition.state
      }
      // state
      cond['lb_id'] = this.rowId
      return cond
    },
    selectArtist(selectInfo) {
      if (selectInfo) {
        this.searchData.artist_id = selectInfo.id
        this.searchData.nickname = selectInfo.nickname
        this.searchData.group_id = selectInfo.group_id
        this.searchData.group_name = selectInfo.group_name
      } else {
        this.searchData.artist_id = null
      }
      this.getKolList()
    },
    handlesearchData() {
      let condition = {}
      if (this.searchData.ym) {
        condition.ym = this.searchData.ym
      }
      if (this.searchData.artist_id) {
        condition.artist_id = this.searchData.artist_id
      }
      return condition
    },
    async getKolList() {
      this.listType = 'all'

      let searchData = this.handlesearchData()
      let type = this.historicalType
      let order = { deadline: 'asc' }
      Object.assign(searchData, { type: type }, this.pagingProduct, { order: order })
      let { list, pages } = await this.$api.getLbScheduleList(searchData)
      this.historicalList = list
      this.pagingProduct.total = pages.total
      this.pagingProduct.current_page = pages.current_page
      this.pagingProduct.page_size = pages.page_size
    }

  }
}
</script>
