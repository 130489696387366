<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'
               :destroy-on-close='true' :close-on-click-modal='false' width='1000px'>
      <el-form :model='formData' size='medium' :rules='rules' ref='ruleForm' label-width='120px'>
        <el-row>
          <el-col :span='8'>
            <el-form-item label='红人昵称' prop='nickname'>
              <span v-if='formData.id'>{{ formData.nickname }}</span>
              <ArtistSearch :name.sync='formData.nickname' @handleSelect='selectArtist' style='display: inline'
                            v-else />
              <!--          &nbsp;&nbsp;<el-button type='text' icon='el-icon-edit'></el-button>-->
            </el-form-item>
          </el-col>
          <el-col :span='8'>
            <el-form-item label='平台' prop='platform_code'>
              <span v-if='formData.id'>{{ formData.platform_name }}</span>
<!--              <PlatformSelect :range-all='true' :purpose='`is_data_entry`' :default-code='formData.platform_code'-->
<!--                              @change='changePlatform' clearable v-else-if='formData.artist_id' />-->
<!--              <span v-else style='color: red'>请先选择红人</span>-->

              <el-select  v-else v-model='formData.platform_code' @change='changePlatform'>
                <el-option v-for='(platform,index) in artist_platforms' :key='index'
                           :value='platform.code'
                           :label='platform.platform_name'
                >
                  <span>{{platform.platform_name}}</span>
                  <span style='float: right;font-size: 0.8em;' >{{platform.account_id||'-'}}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span='8'>
            <el-form-item label='平台ID'>
              <span>{{ formData.platform_account_id||'-' }}</span>
<!--              <span v-else-if='formData.platform_account_id'>{{-->
<!--                  formData.platform_account_id || '/'-->
<!--                }}</span>-->
<!--              <span v-else>请选择红人和平台</span>-->
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label='选品时间' prop='start_at'>
          <!--          <el-date-picker-->
          <!--              v-model='formData.timeRange'-->
          <!--              type='datetimerange'-->
          <!--              align='right'-->
          <!--              range-separator='~'-->
          <!--              start-placeholder='开始日期'-->
          <!--              end-placeholder='结束日期'-->
          <!--              format='yyyy年MM月dd日 HH时mm分'-->
          <!--              value-format='yyyy-MM-dd HH:mm'-->
          <!--              :default-time="['12:00:00', '14:00:00']" style='width: 500px'>-->
          <!--          </el-date-picker>-->
          <el-date-picker
            v-model='formData.start_at'
            type='date'
            format='yyyy年MM月dd日'
            value-format='yyyy-MM-dd'
            placeholder='选择日期'>
          </el-date-picker>
        </el-form-item>
        <el-form-item label='直播时间'>
          <el-date-picker
            v-model='formData.live_time'
            type='date'
            format='yyyy年MM月dd日'
            value-format='yyyy-MM-dd'
            placeholder='选择日期'>
          </el-date-picker>
        </el-form-item>
        <el-form-item label='选品主题' prop='theme'>
          <el-input v-model='formData.theme' placeholder='选品主题' clearable show-word-limit maxlength='200' />
        </el-form-item>
        <el-form-item label='直播项目负责人' prop='leader_id'>
          <div style='display: flex'>
            <div style='display: inline-block'>

              <span :title='`${formData.leader_id}`'>{{ formData.leader_name }}</span>
              &nbsp;
              &nbsp;
              <el-button type='text' icon='el-icon-edit' @click='changeLeader'
                         v-if='userPermissions.indexOf("lb_schedule_edit_leader")>-1'></el-button>
            </div>
            <div style='display: inline-block'>
              &nbsp;
              &nbsp;
              <UserSelect :keyword='formData.leader_name'
                          @selected='handleUserSelect' v-if='editLeader' style='display: inline-block' />
            </div>
          </div>
        </el-form-item>
        <el-form-item label='统筹负责人' prop='overall_id' style='width: 100%'>
          <div style='display: flex'>
            <div style='display: inline-block'>

              <span :title='`${formData.overall_id}`'>{{ formData.overall_name }}</span>
              &nbsp;
              &nbsp;

            </div>

            <div style='display: inline-block;margin-left: 10px'>
              <el-button type='text' icon='el-icon-edit' @click='editAllLeader=!editAllLeader'
                         v-if='userPermissions.indexOf("lb_schedule_edit_leader")>-1'></el-button>
              &nbsp;
              &nbsp;
              <el-select v-if='editAllLeader' @change='handleOverallSelect' v-model='formData.overall_id'
                         placeholder='请选择统筹负责人'>
                <el-option
                  v-for='item in overAllOptions'
                  :key='item.value'
                  :label='item.label'
                  :value='item.value'>
                </el-option>
              </el-select>
              <!--              <UserSelect :leaderId='overall_name'-->
              <!--                          v-show="editAllLeader"-->
              <!--                          ref="child" @selected='handleOverallSelect'-->
              <!--                          style='display: inline-block'/>-->
            </div>
            <div v-show='editAllLeader' style='float: right;display: block;margin-left: 10px'>
              <!--              <el-tag type="success"  effect="plain"  size="medium" style="cursor:pointer" @click="handleOverAll('贺贺')">贺贺</el-tag>-->
              <!--              <el-tag size="medium"   effect="plain"  style="margin-left: 10px;cursor:pointer" @click="handleOverAll('大福')">大福-->
              <!--              </el-tag>-->
              <!--              <el-tag size="medium"    effect="plain" style="margin-left: 10px;cursor:pointer" @click="handleOverAll('王飞')">王飞-->
              <!--              </el-tag>-->
            </div>
          </div>
        </el-form-item>
        <el-form-item label='已有全案品' prop='pit_num'>
          <el-input placeholder='请输入已有全案品的名称，用顿号分隔' v-model='formData.pit_num' clearable show-word-limit
                    :autosize='{minRows:5,maxRows:10}' type='textarea'></el-input>
        </el-form-item>
        <el-form-item label='备注' prop="remark">
          <el-input type='textarea' v-model='formData.remark'
                    :placeholder='`例：本次为8月选品会，\n1.提报春夏应季产品护肤品+博主人设美白系列:贴片面膜、涂抹面膜强功效精华(浓度高、起效快、最好有特证)。\n2.选品方向针对夏天控油、祛痘的产品。防晒喷雾:不带颜色的防晒喷雾(特证优先)【写得越详细越好】`'
                    :autosize='{minRows:5,maxRows:10}' show-word-limit clearable></el-input>
        </el-form-item>
        <!--        <el-form-item v-if='formData.id'>-->
        <!--          <el-button type='text' @click='showNotify=!showNotify'>消息通知-->
        <!--            <span :class="[showNotify?'el-icon-arrow-up':'el-icon-arrow-down']"></span>-->
        <!--          </el-button>-->
        <!--        </el-form-item>-->
      </el-form>
      <div style='text-align: center'>
        <el-button type='primary' size='medium' @click='handleConfirm("ruleForm")' :disabled='loadingCommit'
                   :loading='loadingCommit' v-if='canSave'>
          {{ formData.id ? '保存' : '添加' }}
        </el-button>
        <el-button @click='close' size='medium'>取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import PlatformSelect from '@/pages/platform/components/PlatformSelect'
import { mapGetters } from 'vuex'
import DeptTree from '@/pages/departments/components/DeptTree'
import NotifySend from '@/pages/lb/NotifySend'
import UserSelect from '@/pages/lb/components/UserSelect'

export default {
  name: 'CreateEdit',
  components: { UserSelect, NotifySend, DeptTree, PlatformSelect, ArtistSearch },
  props: {
    id: {
      type: [Number, String]
    },
    defaultFormData: {
      type: Object,
      default() {
        return {
          artist_id: null,
          nickname: null,
          platform_code: null,
          timeRange: null,
          start_at: null,
          end_at: null,
          theme: null,
          leader_id: null,
          leader_name: null,
          pit_num: null,
          product_structure: null,
          remark: null,
          // deadline: null,
          platform_account_id: null

        }
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    dialogTitle() {
      return `${this.id ? '修改' : '添加'}【红人选品排期】`
    },
    canSave() {
      return this.formData.id ? this.userPermissions.indexOf('lb_kol_edit') > -1 : this.userPermissions.indexOf('lb_kol_create') > -1
    }
  },
  data() {
    return {
      loadingCommit: false,
      loadingNotify: false,
      action: 'add',
      editLeader: false,
      editAllLeader: false,
      formData: {
        type: 1,//红人选品
        artist_id: null,
        nickname: null,
        platform_code: null,
        start_at: null,
        end_at: null,
        theme: null,
        leader_id: null,
        leader_name: null,
        pit_num: null,
        product_structure: null,
        remark: null,
        deadline: null,
        platform_account_id: null,
        selection_time: null,
        live_time: null,
        overall_id: null,
        overall_name: null
      },
      lbTimeRange: [],
      rules: {
        nickname: { required: true, message: '请选择红人（KOL）', trigger: 'change' },
        platform_code: { required: true, message: '请选择平台', trigger: 'change' },
        start_at: { required: true, message: '请选择选品时间', trigger: 'blur' },
        theme: { required: true, message: '选品主题必填', trigger: 'blur' },
        // deadline: { required: true, message: '最晚初选时间必填', trigger: 'change' },
        leader_id: { required: true, message: '请选择发起人', trigger: 'change' },
        overall_id: { required: true, message: '请选择统筹负责人', trigger: 'change' },
        pit_num: { required: true, message: '已有全案品必填', trigger: 'change' },
        remark: { required: true, message: '备注必填', trigger: 'change' },
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      options: [],
      showNotify: false,
      product_structure_options: [
        '护肤',
        '彩妆',
        '福利品',
        '女装鞋包',
        '生活日用'
      ],
      artist_platforms: [],
      overall_name: null,
      overAllOptions: []
    }
  },
  methods: {
    // add(){
    //   this.action = 'add'
    //   this.formData = {}
    // },
    // edit(row){
    //   this.action = 'edit'
    //   this.getInfo(row.id)
    // },
    // copy(row){
    //   this.action = 'add'
    //   Object.assign(this.formData, row)
    //   let userInfo = this.$store.state.userInfo
    //   this.formData.leader_id = userInfo.id
    //   this.formData.leader_name = userInfo.name || userInfo.nickname
    // },
    onOpen() {
      this.loadingNotify = false
      this.action = 'add'
      this.editLeader = false
      this.loadLeaderOptions()
      if (this.id) {
        this.action = 'edit'
        this.getInfo(this.id)
      } else {
        this.formData = {
          type: 1,//红人选品
          artist_id: null,
          nickname: null,
          platform_code: null,
          start_at: null,
          end_at: null,
          theme: null,
          leader_id: null,
          leader_name: null,
          pit_num: null,
          product_structure: null,
          remark: null,
          deadline: null,
          platform_account_id: null,
          selection_time: null,
          live_time: null,
          overall_id: null,
          overall_name: null
        }
        Object.assign(this.formData, this.defaultFormData)
        let userInfo = this.$store.state.userInfo
        this.formData.leader_id = userInfo.id
        this.formData.leader_name = userInfo.name || userInfo.nickname
      }

    },
    onClose() {
      // this.$emit('')
      this.formData = {}
    },
    close() {
      this.showNotify = false
      this.$emit('update:visible', false)
    },
    changeLeader() {
      this.editLeader = !this.editLeader

      // this.formData.leader_name
    },
    async getInfo(id) {
      let { info } = await this.$api.getLbScheduleInfo(id)
      this.$nextTick(() => {
        //时间格式的转换前端处理更好，维护起来方便
        // info.timeRange = [this.$utils.parseTime(info.start_at,'{y}-{m}-{d} {h}:{i}'), this.$utils.parseTime(info.end_at,'{y}-{m}-{d} {h}:{i}')]
        // info.timeRange = [info.start_at, info.end_at]
        this.formData = info
        console.log(this.formData)
      })
    },
    async selectArtist(row) {
      this.formData.platform_code = null
      if (row) {
        this.formData.artist_id = row.id || ''
        this.formData.nickname = row.nickname || ''
        let { artist_platforms } = await this.$api.getArtistPlatforms(this.formData.artist_id)
        this.artist_platforms = artist_platforms || []

      } else {
        this.formData.artist_id = null
        this.formData.nickname = null
        this.artist_platforms = []
      }

    },
    async handleConfirm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.formData.artist_id) {
            //
            this.loadingCommit = true
            // this.formData.start_at = this.formData.timeRange[0]
            // this.formData.end_at = this.formData.timeRange[1]
            if (!this.formData.type) {
              this.msgWarn('排期类型未知，无法创建，请联系管理员！！！')
              return false
            }
            let info = await this.$api.saveLbSchedule(this.formData)
            if (info) {
              if (this.formData.id)
                this.msgSuccess('保存成功')
              else
                this.msgSuccess('添加成功')

              setTimeout(() => {
                this.loadingCommit = false
                this.close()
              }, 500)
            }
          } else {
            this.msgWarn('请选择库中红人！')
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          }
        } else {
          console.log('error submit!!')
          this.msgWarn('请填写必填项')
          setTimeout(() => {
            this.loadingCommit = false
          }, 500)
        }

      })
    },
    changePlatform(val) {
      this.formData.platform_code = val
      if (this.artist_platforms && this.artist_platforms.length > 0) {
        let data = this.artist_platforms.find(value => value.code === this.formData.platform_code)
        this.formData.platform_account_id = data?.account_id || '/'
      }
    },
    // 处理搜索结果的选中
    handleUserSelect(item) {
      this.formData.leader_id = item.value
      this.formData.leader_name = item.label
      this.editLeader = false
    },
    // 处理搜索结果的选中
    handleOverallSelect(id) {
      let data = this.overAllOptions.find((item) => {
        return item.value === id
      })
      this.$set(this.formData, 'overall_name', data.label)
      this.$set(this.formData, 'overall_id', data.value)
      //
      // this.formData.overall_id = item.value
      // this.formData.overall_name = item.label
      this.editAllLeader = false
    },
    appendTag(option) {
      if (this.formData.product_structure == null || this.formData.product_structure == '')
        this.formData.product_structure = option
      else
        this.formData.product_structure += '+' + option
    },
    handleOverAll(name) {
      this.editAllLeader = true
      this.overall_name = name
      this.$refs.child.selectData(name)
      // this.$set(this.formData, 'overall_name', name)
    },
    async loadLeaderOptions() {
      //   productLeaderOptions
      let { list } = await this.$api.productLeaderOptions('select_by_role_shang_pin')
      this.overAllOptions = list

    }

  }
}
</script>

<style scoped>
.form-card {
  border: #DCDFE6 1px solid;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}


</style>
