<template>
  <div v-if='isShow' class='article-cover'>
    <!--    <div class='article-title'>-->
    <!--      <span class='title'>{{ articleInfo.title || '' }}</span>-->
    <!--&lt;!&ndash;      <div @click='close'><i class='el-icon-close'></i></div>&ndash;&gt;-->
    <!--    </div>-->
    <div class='slide-down'>
      <article class='article-content' v-html="articleInfo.content||'<span>暂无内容</span>'"></article>
      <div style='text-align: center;width: 100%;margin: 10px'>
        <el-button size='medium' @click='handleRead' type='primary' style='width: 300px;'>
          已&nbsp;&nbsp;阅（{{ readSecond || 0 }}秒）
        </el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'RuleArticle',
  data() {
    return {
      articleInfo: {},
      isShow: false,
      startTime: null,
      readSecond: 0,
      timer: null
    }
  },
  methods: {
    async getRuleArticle() {
      //
      let { info } = await this.$api.getLbDressScheduleRuleArticle()
      this.articleInfo = info || {}
      if (this.articleInfo.id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    close() {
      this.isShow = false
    },
    handleRead() {
      this.isShow = false
    },
    showReadTime() {
      this.readSecond = Math.ceil((Date.now() - this.startTime) / 1000)
      if (this.isShow) {
        this.timer = requestAnimationFrame(() => {
          // console.log(this.readSecond)
          this.showReadTime()
        })
      } else {
        if (this.timer)
          cancelAnimationFrame(this.timer)
      }

    }
  },
  created() {
    this.getRuleArticle()
    this.startTime = Date.now()
    this.isShow = true
    this.showReadTime()
  }
}
</script>

<style scoped>
.article-cover {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  /*transform: translate(-50%, -50%);*/
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  /*border: #E4E7ED 1px dashed;*/
  opacity: 1;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 20px;
  border-radius: 10px;
}

.article-title {
  position: relative;
  top: 10px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.article-title .title {
  /*background: #000c17;*/
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;

}

.article-content {
  background: rgba(255, 255, 255, 1);
  padding: 20px;
  width: clamp(800px, 1200px, 90%);
  margin: auto;
  border-radius: 8px;
}

.slide-down {
  opacity: 0;
  transform: translateY(100%);
  animation: slide-down 2s forwards;
}

@keyframes slide-down {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
