<template>
  <div>
    <el-input
        placeholder="输入部门名称关键字进行过滤"
        v-model="filterText" size="mini" clearable>
    </el-input>
    <el-tree
        v-on="$listeners"
        v-model="selection"
        class="tree-border"
        :data="options"
        show-checkbox
        :default-expand-all="false"
        ref="deptTree"
        node-key="dpt_id"
        :check-strictly="checkStrictly"
        :filter-node-method="filterNode"
        empty-text="加载中，请稍后"
        :props="deptProps"
    ></el-tree>
  </div>
</template>

<script>
export default {
  name: "DingTalkDeptSelect",
  data() {
    return {
      data: [],
      options: [],
      deptProps: {value: 'dpt_id', label: 'name', children: 'children'},
      checkStrictly: false,
      filterText: '',
      selection: null
    }
  },
  watch: {
    filterText(val) {
      this.$refs.deptTree.filter(val);
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    async getList() {
      let {list} = await this.$api.getDepartmentAll('')
      this.data = list
      // list.forEach((item) => {
      //   item.name = `${item.name}(${item.dpt_id})`
      // })
      this.options = this.handleTree(list, 'dpt_id', 'parent_id', 'children', "1")
    },
    // 所有节点数据
    getAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.deptTree.getCheckedKeys();

      //半选中的菜单节点
      let halfCheckedKeys = this.$refs.deptTree.getHalfCheckedKeys();

      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    getCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.deptTree.getCheckedKeys();

      return checkedKeys;
    },
    getCheckedItems() {
      let keys = this.getCheckedKeys();
      let items = []
      keys.forEach((key) => {
        let item = this.data.find(value => value.dpt_id === key)
        items.push(item)
      })

      return items;
    },
    setCheckedKeys(data){
      this.$refs.deptTree.setCheckedKeys(data);
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>

</style>