<template>
  <div v-if='dressRow.id&&userPermissions.indexOf("lb_kol_info")>-1' style='padding-top: 10px'>
    <el-descriptions title='基本信息'>
      <template slot='title'>
        基本信息
        <el-link type='primary' icon='el-icon-edit-outline' @click='handleEdit(dressRow)' :disabled='!canEdit'
                 v-if='userPermissions.indexOf("lb_kol_edit")>-1'
                 style='margin-left: 4px;font-size: 1.1em'></el-link>
      </template>
      <el-descriptions-item label='红人昵称'>{{ dressRow.nickname }}</el-descriptions-item>
      <el-descriptions-item label='直播平台'>{{ dressRow.platform_name }}<span style="color: #E6A23C">
              （平台ID：{{ dressRow.platform_account_id || '/' }} ）
            </span></el-descriptions-item>
      <el-descriptions-item label='选品主题'>
        {{ dressRow.theme }}
      </el-descriptions-item>
      <el-descriptions-item label='选品时间'>
        {{ $utils.parseTime(dressRow.start_at, '{y}年{m}月{d}日') }}
      </el-descriptions-item>
      <el-descriptions-item label='直播时间'>{{ $utils.parseTime(dressRow.live_time, '{y}年{m}月{d}日') }}
      </el-descriptions-item>
      <el-descriptions-item label='已有全案品'>{{ dressRow.pit_num }}</el-descriptions-item>
      <el-descriptions-item label='直播项目负责人'>{{ dressRow.leader_name }}</el-descriptions-item>
      <el-descriptions-item label='统筹负责人'>{{ dressRow.overall_name }}</el-descriptions-item>
      <el-descriptions-item label='备注'>{{ dressRow.remark }}</el-descriptions-item>
    </el-descriptions>
    <!--    创建直播排期-->
    <CreateEdit :visible.sync='EditOpen' :id='dressRow.id' @close='handleClose' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateEdit from '@/pages/lb/kol/CreateEdit.vue'

export default {
  name: 'dressInfo',
  components: {
    CreateEdit
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    canEdit() {
      return (this.userPermissions.indexOf('lb_schedule_edit_leader') > -1 || this.dressRow.leader_id === this.userInfo.id)
    }
  },
  props: {
    dressRow: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      custom_type: 'add',
      EditOpen: false
    }
  },
  methods: {
    handleEdit(row) {
      if (row?.id) {
        this.custom_type = 'edit'
      }
      this.EditOpen = true
    },
    handleClose(){
      this.$emit('close')
    }
  },
  mounted() {
    document.title = `【${this.dressRow.nickname}】红人选品`

  }
}
</script>
