<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'
               width='80%'>

      <el-row :gutter='15'>
        <el-col :span='12'>
          <el-upload
            class='upload-demo'
            :on-change='handleChange'
            :on-success='importSuccess'
            :headers='uploadHeaders'
            :data='postData'
            name='file'
            :show-file-list='false'
            :file-list='file'
            :before-upload='beforeUpload'
            drag
            action='/admin_api/lb_schedule_dress/import_demand'
            multiple>
            <i class='el-icon-upload'></i>
            <div class='el-upload__text'>将文件拖到此处，或<em>点击上传</em></div>
            <div class='el-upload__tip' slot='tip'></div>
          </el-upload>
        </el-col>
        <el-col :span='12'>
          <div>
            <export-btn :can-export='true' ref='refExportLink' type='primary' lintTitle='下载导入模板'
                        @export='downloadTpl'></export-btn>
          </div>
          <div>
            <tips :data='tipList'></tips>
          </div>

        </el-col>
      </el-row>
      <el-row>
        <el-col :span='24'>
          <!--          <div style='margin-bottom: 5px'>-->
          <!--            <span style='font-size: 1.3em;font-weight: bold'>导入数据预览</span>-->
          <!--            <el-link type='primary' @click='showDemo' style='float: right'>查看示例</el-link>-->
          <!--          </div>-->
          <div class='default-table'>
            <el-divider>导入数据预览</el-divider>
<!--            <div style='border: #e97ca1 1px dashed;border-radius: 10px;padding: 5px 10px'>-->
<!--              1）<span style='color: red'>*</span> 需求明细表（若有指定产品，需上传对应图片） 2）秒杀可不填，只要求图片 3）若有指定产品直接填写产品名，若无，请填写具体功效需求-->
<!--              4）根据系统已有类目下拉框做选择（精华/面霜等等）-->
<!--            </div>-->
            <el-table :data='demandList' max-height='500' border>
              <el-table-column label='序号' type='index' align='center' width='60' />
              <el-table-column label='反向需求' prop='demand' align='center' min-width='150' />
              <el-table-column label='客单价' prop='price' align='center'  width='100' />
              <el-table-column label='反向店铺' align='center' prop='store_link'  min-width='150' />
              <el-table-column label='找寻款式' align='center' prop='style_num'  min-width='150' />
              <el-table-column label='参考图片1' prop='title' align='center' width='130'>
                <template v-slot='{row:{refer_cover_url_1}}'>
                  <el-image :src='refer_cover_url_1' style='width: 60px;height: 60px;' width='60px' fit='scale-down'
                            :preview-src-list='[refer_cover_url_1]'>
                    <div slot='error' class='image-slot' style='display: flex;align-items: center;justify-content: center;'>
                      <i class='el-icon-picture-outline'></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label='参考图片2' prop='title' align='center' width='130'>
                <template v-slot='{row:{refer_cover_url_2}}'>
                  <el-image :src='refer_cover_url_2' style='width: 60px;height: 60px;' width='60px' fit='scale-down'
                            :preview-src-list='[refer_cover_url_2]'>
                    <div slot='error' class='image-slot' style='display: flex;align-items: center;justify-content: center;'>
                      <i class='el-icon-picture-outline'></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label='参考图片3' prop='title' align='center' width='100'>
                <template v-slot='{row:{refer_cover_url_3}}'>
                  <el-image :src='refer_cover_url_3' style='width: 60px;height: 60px;' width='60px' fit='scale-down'
                            :preview-src-list='[refer_cover_url_3]'>
                    <div slot='error' class='image-slot' style='display: flex;align-items: center;justify-content: center;'>
                      <i class='el-icon-picture-outline'></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import ExportBtn from '@/components/export/ExportBtn'
import { getToken } from '@/utils/auth'

export default {
  name: 'ImportLbDemand',
  components: { ExportBtn },
  props: {
    id: {
      type: [Number, String]
    }
  },
  data() {
    return {
      dialogTitle: '导入需求明细表',
      file: [],
      uploadLoading: false,
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      postData: { lb_id: null },
      maxSize: 10,//文件最大的 5 M
      tipList: [
        '第1步，下载模板',
        '第2步，按照模板规范填好数据后，根据提示上传文件',
        '注意：1）二次导入，将会追加数据 2）表格空行会过滤'
        // '第3步，预览导入的数据，确认无误后，点击“提交”按钮'
      ],
      demandList: [
        {
          category_name: '美妆—护肤',
          demand: '秋冬保湿补水面霜',
          // price_first: '100-200',
          // num_first: '5',
          // price_second: '200-500',
          // num_second: '3',
          // price_third: '500-1000',
          // num_third: '2',
          title: '1.绽妍  2.颐莲 3.PMPM',
          remark: '请写清楚具体产品及价格范围'
        }
      ]
    }
  },
  methods: {
    onOpen() {
      // this.$notify.info('排期ID：' + this.id)
      this.demandList = []
      // this.showDemo()
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    showDemo() {
      this.demandList = [{
        category_name: '美妆—护肤',
        demand: '秋冬保湿补水面霜',
        // price_first: '100-200',
        // num_first: '5',
        // price_second: '200-500',
        // num_second: '3',
        // price_third: '500-1000',
        // num_third: '2',
        title: '1.绽妍  2.颐莲 3.PMPM',
        remark: '请写清楚具体产品及价格范围'
      }]
    },
    async downloadTpl() {
      try {
        let name = `红人服饰选品会需求明细表-导入模板`
        this.$notify.info(name)
        let queryParams = {
          lb_id: this.id,
          is_tpl: true
        }
        let response = await this.$api.exportLbDressScheduleDemandItem(queryParams)
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    },
    // ======================= 导入相关 =====================
    handleChange(file) {
      this.file = [file]
    },
    beforeUpload(file) {
      //身份凭据
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
      this.postData['lb_id'] = this.id

      this.uploadLoading = true
      const isLt2M = file.size / 1024 / 1024 < this.maxSize
      if (!isLt2M) {
        this.$message.error(`导入文件大小不能超过 ${this.maxSize}MB!`)
        this.uploadLoading = false
      }
      return isLt2M
    },
    importSuccess(response) {
      if (response.code === 200) {
        this.$notify.success('导入成功')
        let sheet_data = response.data ? response.data.sheet_data : []
        this.demandList = sheet_data
        this.$emit('imported')
        // setTimeout(() => {
        //   this.close()
        // }, 500)
      } else {
        this.$message.warning(response.msg || '导入失败')
      }
    }
  }
}
</script>

<style scoped>

</style>
