<template>
  <div class="notify-box">
    <el-form :model="formData" size="small">
      <el-form-item label="通知部门">
        <div class="notify-dept">
          <DingTalkDeptSelect ref="refDngTalkDept"/>
        </div>
      </el-form-item>
      <el-form-item label="同步通知">
        <el-checkbox-group v-model="checked">
          <el-checkbox :label="option.value" v-for="(option,index) in notifyObjOptions" :key="index">
            {{ option.label }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="通知类型">
        <el-radio-group v-model="opType">
          <el-radio :label="option.value" v-for="(option,index) in actionOptions" :key="index"> {{
              option.label
            }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="text-align: center">
        <el-button type="primary" @click="sendNotifyToDepts" icon="el-icon-chat-line-square"
                   :disabled="loadingNotify" :loading="loadingNotify">钉钉通知
        </el-button>
        <el-button type="text" @click="showTaskList=!showTaskList">
          通知记录
          <span :class="[showTaskList?'el-icon-arrow-up':'el-icon-arrow-down']"></span>
        </el-button>
      </el-form-item>
    </el-form>

    <div v-show="showTaskList" class="notify-record">
      <el-link icon="el-icon-refresh" @click="getNotifyTaskList">通知历史记录：</el-link>
      <NotifyTaskList :data-list="taskList" @rowDblclick="showNotifyTask"/>
    </div>
    <div>
      <Tips :data="tipList" :title="``"/>
    </div>

  </div>
</template>

<script>
import NotifyTaskList from "@/pages/lb/NotifyTaskList";
import DingTalkDeptSelect from "@/pages/departments/components/DingTalkDeptSelect";

export default {
  name: "NotifySend",
  components: {DingTalkDeptSelect, NotifyTaskList},
  props: {
    id: {
      type: [Number, String]
    },
    action: {
      type: String,
      default() {
        return 'create'
      }
    }
  },
  computed: {
    opType: {
      get() {
        return this.action
      },
      set(val) {
        this.$emit('update:action', val)
        return val
      }
    }
  },
  data() {
    return {
      tipList:[
        '<b>通知规则：</b><br/>' +
        '1. 《直播排期》新建时，通知到 所有招商+直播负责人<br/>' +
        '2. 《直播排期》修改了排期，通知到 所有招商+直播负责人<br/>' +
        '3. 《直播排期》选品保存时，通知到 相关招商和运营，（品牌库中产品负责人，直播负责人, 排期创建人，对应红人项目组成员）<br/>' +
        '4.  《直播排期》选品修改时，通知到 相关招商和运营，（品牌库中产品负责人，直播负责人，排期创建人，对应红人项目组成员）'
      ],
      loadingNotify: false,
      // opType: 'create',
      formData: {},
      checked: [],
      notifyObjOptions: [
        {label: '直播负责人', value: 'leader'},
        {label: '排期创建人', value: 'creator'},
        {label: '红人项目组成员', value: 'groupMember'},
        {label: '产品负责人', value: 'productLeader'},
      ],
      actionOptions: [
        {label: '排期新建', value: 'add'},
        {label: '排期修改', value: 'edit'},
        {label: '直播选品', value: 'selection'},
      ],
      taskList: [],
      showTaskList: false,
    }
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.checked = []
        this.getNotifyTaskList()
      }
    }
  },
  methods: {
    close(){
      this.$emit('close')
    },
    async notifyDingTalk(postData) {
      this.loadingNotify = true
      if (this.id) {
        let info = await this.$api.dingTalkNotifyLbScheduleAction(postData)
        this.loadingNotify = false
        if (info) {
          this.$notify.success('发送成功')
          // await this.getNotifyTaskList()
          this.close()
        }
      }

    },
    //获取任务ID
    async getNotifyTaskList() {
      if (this.id) {
        let {list} = await this.$api.dingTalkNotifyLbScheduleTaskList({lb_id: this.id})
        this.$nextTick(() => {
          this.taskList = list
        })
      }

    },
    sendNotifyToDepts() {
      let deptIds = this.$refs.refDngTalkDept.getCheckedKeys()
      let depts = this.$refs.refDngTalkDept.getCheckedItems()
      let postData = {
        dept_ids: deptIds,
        other: this.checked,
        op_type: this.opType,
        id: this.id
      }

      let deptNames = depts.map((item) => {
        return item.name
      })
      let msg = ''

      let otherObj = []
      this.checked.forEach((v) => {
        let option = this.notifyObjOptions.find(value => value.value === v)
        otherObj.push(option.label)
      })
      if (otherObj.length > 0)
        msg += `【${otherObj.join('、')}】`

      if (deptNames.length > 0)
        msg += `【${deptNames.join('、')}】`

      postData.remark = msg

      this.$confirm(`确认给${msg}发送钉钉通知?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.notifyDingTalk(postData)
        this.$emit('refresh', true)

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消通知'
        });
      });

    },
    showNotifyTask(row) {
      let reqParam = row.req_param;
      this.checked = reqParam.other || []
      this.opType = reqParam.op_type || null
      this.$refs.refDngTalkDept.setCheckedKeys(reqParam.dept_ids || []);
    }
  },
  created() {

  }
}
</script>

<style scoped>
.notify-box {
  border: #DCDFE6 1px solid;
  padding: 10px;
  /*max-height: 400px;*/
  /*overflow-y: scroll;*/
}

.notify-dept {
  width: 350px;
  border: #DCDFE6 1px solid;
  padding: 10px;
  max-height: 300px;
  overflow-y: scroll;
}

.notify-record {
  margin-top: 10px;
  border: #DCDFE6 1px solid;
  padding: 10px;
  max-height: 200px;
  overflow-y: scroll;
}
</style>
