<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" @open='onOpen'
             :title="`服饰库-${custom_type==='add'?'添加':'修改'}产品信息`"
             :before-close='beforeClose' width='880px' center>
    <el-form ref='formData' :model='formData' :rules='rules' label-position='left'
             label-width='130px'>
      <el-row :gutter='20' style='border: 1px dashed red; padding: 20px'>
        <el-col :span='12'>
<!--          <el-form-item label='品牌' prop='brand_name'>-->
<!--            <el-row :gutter='10'>-->
<!--              <el-col :span='18'>-->
<!--                <el-input v-model='formData.brand_name' clearable></el-input>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-form-item>-->
          <el-form-item label='可播平台' prop='platform_code'>
            <PlatformSelect @saved='handleSelectPlatform' :range-all='true' v-model='formData.platform_code'
                            placeholder='请选择可播平台' :default-code='formData.platform_code'/>
          </el-form-item>
          <el-form-item label='产品/品牌' prop='title'>
            <el-row :gutter='10'>
              <el-col :span='18'>
                <el-input v-model='formData.title' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
<!--          <el-form-item label='颜色' prop='spec_color'>-->
<!--            <el-row :gutter='10'>-->
<!--              <el-col :span='18'>-->
<!--                <el-input v-model='formData.spec_color' clearable></el-input>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-form-item>-->
          <el-form-item label='尺码/颜色' prop='spec_name'>
            <el-row :gutter='10'>
              <el-col :span='18'>
                <el-input v-model='formData.spec_name' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='日常价(元)' prop='price'>
            <el-row :gutter='10'>
              <el-col :span='18'>
                <el-input v-model='formData.price' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='直播价(元)' prop='lb_price'>
            <el-row :gutter='10'>
              <el-col :span='18'>
                <el-input v-model='formData.lb_price' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='赠品/赠品价值' prop='gift'>
            <el-row :gutter='10'>
              <el-col :span='18'>
                <el-input v-model='formData.gift' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='佣金(%)' prop='commission'>
            <el-row :gutter='10'>
              <el-col :span='18'>
                <el-input v-model='formData.commission' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='保价情况' prop='support_situation'>
            <el-row :gutter='10'>
              <el-col :span='18'>
                <el-input v-model='formData.support_situation' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='店铺评分' prop='score'>
            <el-row>
              <el-col :span='18'>
                <el-input v-model='formData.score' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span='12' style="text-align: center">
          <ApeUploader :limit='1' :upload-file-list='uploadFileList'
                       @handleUploadRemove='handleUploadRemove'
                       @handleUploadSuccess='handleUploadSuccess'
                       uploadTip='请上传图片，最多上传1份。大小限制2M文件'></ApeUploader>
          <br>
<!--          <el-form-item label='寄样时间' prop='sample_date'>-->
<!--            <el-row>-->
<!--              <el-col :span='18'>-->
<!--                <el-date-picker-->
<!--                    value-format="yyyy-MM-dd"-->
<!--                    v-model="sample_date"-->
<!--                    type="date"-->
<!--                    placeholder="选择日期">-->
<!--                </el-date-picker>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-form-item>-->
<!--          <el-form-item label='寄样情况' prop='sample_situation'>-->
<!--            <el-row>-->
<!--              <el-col :span='18'>-->
<!--                <el-input v-model='formData.sample_situation' clearable></el-input>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-form-item>-->
          <el-form-item label='寄样进度' prop='sample_progress'>
            <el-row>
              <el-col :span='18'>
                <el-input v-model='formData.sample_progress' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='对接人联系方式' prop='contact_info'>
            <el-row>
              <el-col :span='18'>
                <el-input v-model='formData.contact_info' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label='退货情况' prop='return_situation'>
            <el-row>
              <el-col :span='20'>
                <el-input type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}" v-model='formData.return_situation' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='库存(现货库存及预售库存)' prop='stock_info'>
            <el-row>
              <el-col :span='20'>
                <el-input type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}" v-model='formData.stock_info' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='发货时效(现货时效及预售时效)' prop='delivery'>
            <el-row >
              <el-col :span='20'>
                <el-input type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4}" v-model='formData.delivery' clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label='备注' prop='remark'>
            <el-row>
              <el-col :span='20'>
                <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" v-model='formData.remark'
                          clearable></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <span slot='footer' class='dialog-footer'>
        <el-button @click='beforeClose'>取 消</el-button>
        <el-button type='primary' @click='handelStore'>确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import ApeUploader from '@/components/ApeUploader'
import PlatformSelect from '@/pages/lb/components/PlatformSelect'

const rules = {
  title: [
    { required: true, message: '请输入品牌名', trigger: 'blur' },
    { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
  ]
}
export default {
  name: 'AddDress',
  components: {
    ApeUploader,PlatformSelect
  },
  data() {
    return {
      rules: rules,
      uploadFileList: [],
      selectDress: false,
      formData: {
        sample_date: null
      },
      sample_date: ''
    }
  },
  props: {
    formStore: {
      type: Object
    },
    custom_type: {
      type: String
    }
  },
  watch: {
  }
  ,
  methods: {

    onOpen() {
      this.formData = this.formStore
      this.sample_date = this.formData.sample_date || ''
      this.uploadFileList = this.formData.cover_url ? [{ url: this.formData.cover_url }] : []
    },
    //关闭弹窗
    beforeClose() {
      this.$emit('update:visible', false)
      this.uploadFileList = []
      this.formData = {}
    }
    ,
    // 图片删除回调
    handleUploadRemove() {
      this.formData.cover_url = 'http://tisu-pd.oss-cn-hangzhou.aliyuncs.com/platform_beta/20220719/T20220719142555_32702.png'
    }
    ,
    handleUploadSuccess(file) {
      this.formData.cover_url = file.full_path
    },
    handelStore() {
      this.formData.sample_date = this.sample_date
      this.$emit('handelStore', this.formData)
      // saveLbDressSelectionInfo
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formData.validate(async (valid) => {
          if (valid) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },

  handleSelectPlatform(val) {
    this.formData.platform_code = val ? val.code : []
    this.formData.platform_name = val ? val.name : []
  }

  }
}
</script>

<style scoped>

</style>
