<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'
               width='1000px'>
      <div>
        <el-divider content-position='left'>发起需求-通知招商选品</el-divider>
        <el-row style='margin-bottom: 8px'>
          <el-col :span='1.5'>
            <el-link type='primary' icon='el-icon-refresh' @click='init'>刷新</el-link>
          </el-col>
          <el-col :span='1.5'>
            &nbsp;&nbsp;<el-link type='primary' icon='el-icon-s-promotion' :disabled='multiple'
                                 @click='sendNotifyMultiple'>发送通知
          </el-link>
          </el-col>
        </el-row>
        <el-row :gutter='15'>
          <el-col :span='12'>
            <div class='default-table'>
              <el-table :data='notifyUsers' ref='refTbl' row-key='id' @selection-change='handleSelectRows'
                        max-height='500' border>
                <el-table-column type='selection' :reserve-selection='true' width='55'></el-table-column>
                <el-table-column label='序号' type='index' width='50' align='center'></el-table-column>
                <el-table-column label='姓名（昵称）' prop='name' min-width='120' align='center'></el-table-column>
                <el-table-column label='操作' min-width='100' align='center'>
                  <template slot-scope='{row}'>
                    <el-button type='text' icon='el-icon-s-promotion' @click='sendNotifySingle(row)'>发送通知</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
          <el-col :span='12'>
            <div style='min-height: 100px;' class='notify-box'>
              <el-tag size='medium ' type='primary' effect='plain' :label='user.name' :value='user.userid'
                      style='margin-right: 5px'
                      v-for='(user,key) in selectedList'
                      :key='key'>
                {{ key + 1 }}）{{ user.name }} <span v-if='user.position'>[{{ user.position }}]</span>
              </el-tag>
            </div>
            <div style='margin-top: 20px;text-align: center'>
              <el-button type='primary' size='medium' :disabled='multiple' icon='el-icon-s-promotion'
                         @click='handleSendNotify' v-if='info.progress_status>1&&info.progress_status<4'>
                通知以上人员({{ selectedList.length }})，尽快进行选品
              </el-button>
            </div>
          </el-col>
        </el-row>

        <div style='width: 100%;text-align: center;padding: 10px'
             v-if="userPermissions.indexOf('lb_kol_demand_launch')>-1">
          <el-checkbox v-model='checkNotify' v-if='info.progress_status===1'>通知</el-checkbox>
          &nbsp;
          &nbsp;
          <el-button type='primary' size='medium' @click='subCommit' style='width: 200px'
                     :disabled='info.progress_status>1' v-if='info.progress_status===1'>
            {{ this.checkNotify ? '确认发起选品需求' : '仅发起需求' }}
          </el-button>

        </div>
        <el-divider content-position='left'>钉钉通知-参与选品会议</el-divider>
        <div class='task-box'>
          <div>
            <el-checkbox v-model='checkedTask'>《钉钉通知》</el-checkbox>
            <span v-if='taskContent.id'>
              <el-tag effect='plain' type='success'
                      v-if='taskContent.notify_status===2'> {{ taskContent._notify_status || '' }}</el-tag>
              <el-tag effect='plain' v-else> {{ taskContent._notify_status || '未创建' }}</el-tag>
            </span>
            <el-tag effect='plain' type='info' v-else> {{ '待创建' }}</el-tag>
          </div>
          <div v-if='userPermissions.indexOf("lb_notify_task_info")>-1'>
            <el-form label-width='100px'>
              <el-form-item label='通知时间'>
                <template slot='label'>
                  <b>通知时间：</b>
                </template>
                <el-date-picker
                  v-model='taskContent.notify_time'
                  type='datetime'
                  placeholder='选择日期时间'
                  default-time='13:00:00'
                  format='yyyy-MM-dd HH:mm:ss'
                  value-format='yyyy-MM-dd HH:mm:ss'
                  align='right'
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label='通知内容'>
                <template slot='label'>
                  <b>通知内容：</b>
                </template>
                <el-input style='width: 90%' placeholder='例如：请提报的招商收到通知后参加《红人选品会议》' type='textarea'
                          :autosize='{minRows: 5, maxRows: 10}'
                          v-model='taskContent.notify_msg'></el-input>
              </el-form-item>
              <el-form-item label=''>
                <el-button v-if='userPermissions.indexOf("lb_notify_task_create")>-1' type='primary'
                           @click='handleCreateTask' icon='el-icon-chat-dot-square'>确认通知
                </el-button>
                <el-button v-if='userPermissions.indexOf("lb_notify_task_cancel")>-1' @click='handleCancelTask'
                           :disabled='!taskContent.id' icon='el-icon-refresh-left'>取消通知
                </el-button>
              </el-form-item>
            </el-form>
            <div>
              <span class='el-icon-info'> 未发送的通知任务可修改通知内容或取消通知</span>
            </div>
          </div>
        </div>


      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CommitDemandConfirm',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  props: {
    id: {
      type: [Number, String]
    },
    configType: {
      type: String,
      default: 'kol'

    }
  },
  data() {
    return {
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      dialogTitle: '红人选品会-发起需求',
      info: {},
      notifyUsers: [
        // { id: 'pl21222', name: '用户XX', userid: 'XX', position: '招商' },
      ],
      checkNotify: true,
      selectedList: [],//预览数据列表
      checkedTask: true,//定时任务
      taskList: [],//定时任务列表（根据排期ID查询）
      taskContent: {
        // id: null,
        lb_id: null,
        notify_msg: '提报的招商参加《红人选品会》',
        // notify_status: 1,
        // _notify_status: null,
        notify_time: null//发送通知时间
        // created_by: null
      }//任务内容

    }
  },
  methods: {
    onOpen() {
      this.$nextTick(() => {
        this.reset()
        // this.$notify.info('发起需求')
        this.init()
      })

    },
    onClose() {
      this.reset()
    },
    close() {
      this.$emit('update:visible', false)
    },
    reset() {
      this.notifyUsers = []
      this.ids = []
      this.single = true
      this.multiple = true
      this.info = {}
      this.selectedList = []
    },
    async init() {
      this.taskContent.lb_id = this.id
      await this.getInfo()
      await this.getNotifyUsers()
    },
    async getInfo() {
      let { info } = await this.$api.getLbScheduleInfo(this.id)
      this.$nextTick(() => {
        this.info = info
        this.dialogTitle = `【红人选品会${this.configType==='dress'?'-服饰选品':''}-发起需求】${this.$utils.parseTime(this.info.start_at, '{y}年{m}月{d}日')} 【${this.info.nickname}】 ${this.info.platform_name}`
        // 获取定时任务
        this.getLbNotifyTaskInfo()
      })

    },
    async getNotifyUsers() {
      let { list } = await this.$api.getLbScheduleNotifyUser()
      // this.selectedList = [...list]
      this.notifyUsers = list
      // 默认全部选中
      this.$nextTick(() => {
        if (this.$refs['refTbl'])
          this.$refs['refTbl'].toggleAllSelection()
      })

    },
    // handleClose(tag) {
    //   this.notifyUsers.splice(this.notifyUsers.indexOf(tag), 1)
    //   this.selectedList.splice(this.selectedList.indexOf(tag), 1)
    // },
    handleCreateTask() {
      //
      this.$confirm(`确认提交定时通知任务`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.saveLbNotifyTask()
      })

    },
    handleCancelTask() {
      this.$confirm(`确认取消定时通知任务，取消后可以重新创建通知任务`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.cancelLbNotifyTask()
      })
    },
    async subCommit() {
      this.$confirm(`确认发起需求`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.launchLbSchedule(this.id)
        if (info === 'ok') {
          this.msgSuccess('已发起需求')
          if (this.checkNotify) {
            this.handleSendNotify()
          }
          if (this.checkedTask) {
            await this.saveLbNotifyTask()
          }
        }
      })
    },
    async saveLbNotifyTask() {
      let id = await this.$api.saveLbNotifyTaskInfo(this.taskContent)
      if (id) {
        this.$notify.success('定时任务已创建')
        // this.taskContent.id = id
        await this.getLbNotifyTaskInfo()
      }
    },
    async cancelLbNotifyTask() {
      if (this.taskContent.id) {
        let info = await this.$api.deleteLbNotifyTaskInfo(this.taskContent.id)
        if (info) {
          this.$notify.success('定时任务已取消')
          // this.taskContent = {}
          await this.getLbNotifyTaskInfo()
        }
      }
    },
    async getLbNotifyTaskInfo() {
      if (this.userPermissions.indexOf('lb_notify_task_info') === -1) {
        return false
      }
      let { info } = await this.$api.queryLbNotifyTaskInfo(this.id)
      if (info.id) {
        this.taskContent = { ...info }
      } else {
        this.taskContent = {
          lb_id: this.id,
          notify_msg: '提报的招商参加《红人选品会》',
          notify_time: null//发送通知时间
        }
        //先创建，默认定时任务发送时间为 5天后1点
        let date = new Date()
        //分页页面-默认上月
        date.setDate(date.getDate() + 5)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate() + 1
        this.taskContent.notify_time = `${year}-${month}-${day} 13:00:00`
        this.taskContent.notify_msg = `请提报的招商收到通知后参加《${this.$utils.parseTime(this.info.start_at, '{y}年{m}月{d}日')}/【${this.info.nickname}】/${this.info.platform_name} 红人选品会》`
      }

    },
    handleSendNotify() {
      if (this.selectedList.length > 0)
        this.sendNotifyMultiple()
      else
        this.$notify.warning('请先勾选发送消息的对象')
    },
    sendNotifySingle(row) {
      this.$confirm(`通知 “${row.name}”，尽快对直播排期（【${this.info.nickname}】 ${this.$utils.parseTime(this.info.start_at, '{y}年{m}月{d}日')} ${this.info.platform_name}）进行初选选品`, '消息通知', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sendNotify([row])
      })
    },
    sendNotifyMultiple() {
      this.$confirm(`确认通知产品负责人（ ${this.selectedList.length} 人），尽快对直播排期（【${this.info.nickname}】 ${this.$utils.parseTime(this.info.start_at, '{y}年{m}月{d}日')} ${this.info.platform_name}）进行初选选品？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sendNotify(this.selectedList)
      })
    },
    async sendNotify(notifyUsers) {
      let info = await this.$api.sendNotifyLbSchedule(notifyUsers, this.id)
      if (info === 'ok') {
        this.$notify.success('通知已发送')
        this.close()
      }
    },
    handleSelectRows(selection) {
      this.selectedList = selection || []
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    }
  }
}
</script>

<style scoped>
.notify-box {
  border: #ff3176 1px dashed;
  line-height: 30px;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
}

.task-box {
  border: #ff3176 1px dashed;
  line-height: 30px;
  width: 96%;
  padding: 10px;
  border-radius: 5px;
}
</style>
